@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  height: 100%;
}

:root {
  --auto-grid-min-size: 16rem;
  --auto-grid-min-s-size: 9rem;
}

body {
  background-color: #eaeaea;
  padding: 0;
  margin: 0;
}

.ui.fixed.menu {
  background-image: linear-gradient(135deg, rgb(24, 42, 115), 0%, rgb(33, 138, 174), 69%, rgb(32, 167, 172) 89%);
}

.ui.main.container {
  margin-top: 7em;
}

.masthead {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: #007c64;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: 0;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

/* .active {
  text-decoration: underline;
} */
/* a:hover {
  color: #a2d2ff;
  text-decoration: none;
} */

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #5046e5;
}

::selection {
  color: white;
  background: #5046e5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
}
.auto-grid-s {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-s-size), 1fr));
  grid-gap: 1rem;
}
